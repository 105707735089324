import { Download } from '@app/shared';

/**
 * Regenerate download
 */
export class RegenerateDownload {
  static readonly type = '[DOWNLOADS] RegenerateDownload';
  constructor(public userId: string, public downloadId: string) {}
}

export class RegenerateDownloadSuccess {
  static readonly type = '[DOWNLOADS] RegenerateDownloadSuccess';
  constructor(public payload: Download) {}
}

export class RegenerateDownloadError {
  static readonly type = '[DOWNLOADS] RegenerateDownloadError';
}

/**
 * Delete download
 */
export class DeleteDownload {
  static readonly type = '[DOWNLOADS] DeleteDownload';
  constructor(public userId: string, public downloadId: string) {}
}

export class DeleteDownloadSuccess {
  static readonly type = '[DOWNLOADS] DeleteDownloadSuccess';
  constructor(public payload: Download) {}
}

export class DeleteDownloadError {
  static readonly type = '[DOWNLOADS] DeleteDownloadError';
}

/**
 * Websocket actions
 */
/** @deprecated */
export class PageDownloadGenerated {
  static readonly type = '[DOWNLOADS] PageDownloadGenerated';
  constructor(public payload: Download) {}
}
